import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-campaign-rules",
  templateUrl: "./campaign-rules.component.html",
  styleUrls: ["./campaign-rules.component.scss"],
})
export class CampaignRulesComponent implements OnInit {
  listItems: string[][] = [
    [
      "Il Bonus Prevenzione ICTUS è valido solo se consegnato a un punto vendita Salute (Farmacia, Parafarmacia, Sanitaria, ecc) aderente alla campagna Prevenzione ICTUS (vedi store locator su www.prevenzioneictus.it ).",
      "NON È VALIDO PER PROMOZIONI INTERNET O ACQUISTI ON LINE.",
    ],
    [
      "Il Bonus è valido solo per l’acquisto del prodotto MICROLIFE AFIB ADVANCED.",
    ],
    [
      "È possibile scaricare il Bonus con il proprio Codice Fiscale dal sito www.prevenzioneictus.it o tramite la APP Prevenzione ICTUS.",
    ],
    [
      "È possibile scaricare solo 1 Bonus per persona maggiorenne - VIETATO AI MINORI.",
    ],
    ["È possibile utilizzare il Bonus per 1 solo acquisto."],
  ];

  constructor() {}

  ngOnInit() {}
}
