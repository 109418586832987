import { Component, ComponentRef } from "@angular/core";
import { IModalDialog, IModalDialogOptions } from "ngx-modal-dialog";

@Component({
  selector: "app-reseller-campaign-terms-modal",
  templateUrl: "./reseller-campaign-terms-modal.component.html",
  styleUrls: ["./reseller-campaign-terms-modal.component.scss"],
})
export class ResellerCampaignTermsModalComponent implements IModalDialog {
  listItems: string[][] = [
    [
      "Il Bonus Prevenzione ICTUS può essere gestito, erogato e rimborsato SOLO ai punti vendita Salute (Farmacia, Parafarmacia, Sanitaria, ecc) registrati alla campagna Prevenzione ICTUS",
    ],
    [
      "Il Bonus è valido solo per vendite effettuate presso un punto vendita Salute (Farmacia, Parafarmacia, Sanitaria, ecc) aderente alla campagna Prevenzione ICTUS.",
      "NON È VALIDO PER PROMOZIONI O VENDITE INTERNET.",
    ],
    [
      "Qualora il punto vendita Salute utilizzasse direttamente o indirettamente il Bonus per vendite o promozioni INTERNET (o per altri usi non consentiti) verrà bannato dalla campagna e non avrà diritto a rimborsi successivi alla data di cancellazione della registrazione.",
    ],
    [
      "Il Bonus è valido solo per l’acquisto del prodotto MICROLIFE AFIB ADVANCED. NON È VALIDO PER ALTRI PRODOTTI MICROLIFE ANCHE SE DOTATI DI TECNOLOGIA AFIB.",
    ],
    [
      "Il Bonus può essere scaricato direttamente dal paziente o dal punto vendita Salute per il paziente tramite il codice fiscale",
    ],
    [
      "Il Bonus verrà rimborsato al punto vendita Salute solo se appositamente registrato (6 numeri), tramite APP o sito www.prevenzioneictus.com.",
    ],
    [
      "È possibile registrare e utilizzare il Bonus 1 sola volta. Dopo la registrazione il Bonus viene «bruciato».",
    ],
    [
      "I Bonus verranno rimborsati direttamente in fattura nell’ordine effettuato con COLPHARMA all’apposita voce «rimborso Bonus PREVENZIONE ICTUS» per un valore pari a 35,00 euro scontato del 60% (sconto lordo) pari a 14,00 euro + IVA.",
      "Comunicare all’agente COLPHARMA di volere effettuare il rimborso dei Bonus.",
    ],
    [
      "I Bonus rimborsati non potranno eccedere il numero di prodotti MICROLIFE AFIB ADVANCED acquistati da COLPHARMA o tramite Transfer Order aderente all’iniziativa.",
    ],
    [
      "Il calcolo dei Bonus rimborsati considererà l’ultimo ordine effettuato di MICROLIFE AFIB ADVANCED. Eventuali ordini retroattivi non verranno considerati per il calcolo dei Bonus.",
    ],
    [
      "Non possono essere conteggiati per l’iniziativa eventuali prodotti NON acquistati da COLPHARMA, tramite Transfer Order non aderente alla campagna, acquisti da grossisti o altro in quanto non tracciabili da parte di COLPHARMA.",
    ],
    [
      "Il numero di Bonus registrati, addebitati e da addebitare sarà riportato nell’apposita sezione della propria utenza tramite APP o sito www.prevenzioneictus.com",
    ],
  ];

  constructor() {}

  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<any>>
  ) {}
}
